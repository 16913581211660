import ReactDOM from "react-dom/client"
import { Provider } from "react-redux";
import { store } from "store";
import { App } from "App";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"
import { GlobalWorkerOptions } from "pdfjs-dist"

import "./index.css"

GlobalWorkerOptions.workerSrc = pdfjsWorker;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
