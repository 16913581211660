import { LeadMagnetPage } from "modules/LeadMagnet/page/LeadMagnet.page"
import { BrowserRouter } from "react-router-dom"
import { useAppSelector } from "store"

export const App = () => {
  const isLeadMagnetLoading = useAppSelector(
    (x) => x.leadMagnet.isLoading
  )

  const isLeadMagnetRendering = useAppSelector(
    (x) => x.leadMagnet.isRendering
  )

  return (
    <>
      <BrowserRouter>
        <div className="flex h-dvh">
          <LeadMagnetPage />
        </div>
      </BrowserRouter>

      {(
            isLeadMagnetLoading
        ||  isLeadMagnetRendering
      ) ? (
        <div className="z-[999999] fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white bg-opacity-25">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-e-transparent border-black align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_interface] dark:text-white" />
        </div>
      ) : null}
    </>
  )
}
