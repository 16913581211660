import { createSlice } from "@reduxjs/toolkit"
import { addOpenedPageEvent } from "services/api.event"
import { searchLeadMagnet } from "services/api.leadmagnet"
import { PageEventResponse } from "types"

interface EventState {
  isModifying: boolean
  basePageEvent: PageEventResponse | null
  _links: any
}

const initialState: EventState = {
  isModifying: false,
  basePageEvent: null,
  _links: {}
}

const eventSlice = createSlice({
    name: "event",

    initialState: initialState,

    reducers: {
      //
    },

    extraReducers: (builder) =>
      builder
        //
        // Lead Magnet Search
        //
        .addCase(searchLeadMagnet.fulfilled, (state, action) => {
            const {
              eventAdd
            } = action.payload._links

            state._links = {
              ...state._links,
              eventAdd
            }
          }
        )

        //
        // Opened Page Event Add
        //
        .addCase(addOpenedPageEvent.fulfilled, (state, action) => {
            state.basePageEvent = action.payload.pageEvent
          }
        )
  }
)

export const {
  reducer: eventReducer
} = eventSlice
