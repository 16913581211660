import { configureStore } from "@reduxjs/toolkit"
import { eventReducer } from "modules/Event/Event.slice"
import { leadMagnetReducer } from "modules/LeadMagnet/LeadMagnet.slice"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

export const store = configureStore({
    reducer: {
      leadMagnet: leadMagnetReducer,
      event: eventReducer
    }
  }
)

export type AppStore = typeof store

export type AppDispatch = typeof store.dispatch

export type AppState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector