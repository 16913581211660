import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getLeadMagnetFile, searchLeadMagnet } from "services/api.leadmagnet"
import { LeadMagnetResponse } from "types"

interface LeadMagnetState {
  isLoading: boolean
  isRendering: boolean
  leadMagnet: LeadMagnetResponse | null
  _links: any
}

const initialState: LeadMagnetState = {
  isLoading: false,
  isRendering: false,
  leadMagnet: null,
  _links: {
    "leadMagnetSearch": "/api/leads/leadmagnets"
  },
}

const leadMagnetSlice = createSlice({
    name: "leadMagnet",

    initialState: initialState,

    reducers: {
      /**
       * Ustawia czy renderowane
       */
      setRendering: (state, action: PayloadAction<boolean>) => {
        state.isRendering = action.payload
      }
    },

    extraReducers: (builder) => 
      builder
        //
        // LeadMagnet Search
        //
        .addCase(searchLeadMagnet.pending, (state) => {
            state.isLoading = true
          }
        )

        .addCase(searchLeadMagnet.fulfilled, (state, action) => {
            state.isLoading = false

            const {
              leadMagnetFileGet
            } = action.payload._links

            state._links = {
              ...state._links,
              leadMagnetFileGet
            }

            state.leadMagnet = action.payload.leadMagnet
          }
        )

        .addCase(searchLeadMagnet.rejected, (state) => {
            state.isLoading = false
          }
        )

        //
        // LeadMagnet File Get
        //
        .addCase(
          getLeadMagnetFile.pending, (state) => {
            state.isLoading = true
          }
        )

        .addCase(
          getLeadMagnetFile.fulfilled, (state) => {
            state.isLoading = false
          }
        )

        .addCase(
          getLeadMagnetFile.rejected, (state) => {
            state.isLoading = false
          }
        )
  }
)

export const {
  setRendering
} = leadMagnetSlice.actions

export const {
  reducer: leadMagnetReducer
} = leadMagnetSlice
