import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "./api";
import { LeadMagnetResponse } from "types";

export const searchLeadMagnet = createAsyncThunk("app/searchLeadMagnet", (
    { leadMagnetId, leadId }: { leadMagnetId: string, leadId: string }, { getState }
  ) => {
    const state: any = getState()

    const { leadMagnetSearch } = state.leadMagnet._links

    return api.get<{
        leadMagnet: LeadMagnetResponse | null
        _links: any
      }
    >(leadMagnetSearch, {
        params: {
          leadMagnetId, leadId,
        }
      }
    )

    .then(
      (x) => x.data
    )
  }
)

export const getLeadMagnetFile = createAsyncThunk("app/getLeadMagnetFile", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { leadMagnetFileGet } = state.leadMagnet._links

    return api.get(leadMagnetFileGet, {
        responseType: "blob"
      }
    )

    .then(
      (x) => x.data
    )
  }
)
