import axios from "axios";

export const api = axios.create({
    baseURL: process.env.REACT_APP_API,

    validateStatus: (status) => {
      return (status >= 200 && status < 400) || status === 404
    }
  }
)
