import { createAsyncThunk } from "@reduxjs/toolkit";
import { PageEventRequest, PageEventResponse } from "types";
import { api } from "./api";
import { resolveMessage } from "./api.error";

export const addOpenedPageEvent = createAsyncThunk("app/addOpenedPageEvent", (
    request: {
      openedPageEvent?: PageEventRequest
    }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { eventAdd } = state.event._links

    return api.post<{
        pageEvent: PageEventResponse
      }
    >(
      eventAdd, request
    )

    .then(
      (x) => x.data
    )

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const addReadingPageEvent = createAsyncThunk("app/addReadingPageEvent", (
    request: {
      readingPageEvent?: PageEventRequest
    }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { eventAdd } = state.event._links

    const eventBaseId = state.event.basePageEvent?.eventId

    return api.post<{
        pageEvent: PageEventResponse
      }
    >(
      eventAdd, { readingPageEvent: { ...request.readingPageEvent, eventBaseId }}
    )

    .then(
      (x) => x.data
    )

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)
